@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-mb0 {
  margin-bottom: 0 !important; }

.c-slider {
  padding: 0;
  line-height: 0; }
  .c-slider__item img {
    width: 100%;
    display: block;
    line-height: 1; }
  .c-slider .slick-prev, .c-slider .slick-next {
    z-index: 1; }
  .c-slider .slick-prev {
    left: 25px; }
  .c-slider .slick-next {
    right: 25px; }

.c-mv {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .c-mv--pc {
    position: relative;
    display: block; }
    @media screen and (max-width: 568px) {
      .c-mv--pc {
        display: none; } }
    .c-mv--pc .slogan {
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      z-index: 1;
      width: 30vw; }
      @media screen and (max-width: 741px) {
        .c-mv--pc .slogan {
          top: 47%; } }
  .c-mv--sp {
    display: none; }
    @media screen and (max-width: 568px) {
      .c-mv--sp {
        display: block; } }
    .c-mv--sp .slogan {
      padding: 30px 0 30px 30px; }
  .c-mv .animation-box {
    position: relative;
    width: 100%; }
    .c-mv .animation-box--pc {
      padding-top: 53.9%; }
      .c-mv .animation-box--pc > div {
        position: absolute;
        top: 0;
        left: 3%;
        width: 100%;
        height: 100%; }
    .c-mv .animation-box--sp {
      padding-top: 100%; }
      .c-mv .animation-box--sp > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

.main-lines-pc {
  width: 100vw;
  height: 100%;
  background: url("/inc/image/top/main_lines_pc.svg") no-repeat bottom 48% left 0/contain;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; }
  @media screen and (max-width: 926px) {
    .main-lines-pc {
      background-position: bottom 37% left 0; } }
  @media screen and (max-width: 741px) {
    .main-lines-pc {
      background-position: bottom 35% left 0; } }
  @media screen and (max-width: 646px) {
    .main-lines-pc {
      background-position: bottom 30% left 0; } }

@media screen and (max-width: 568px) {
  .main-lines-sp {
    background: url("/inc/image/top/main_lines_sp.svg") no-repeat bottom 20% left -17px/cover; } }

#top #wrapper {
  padding: 0 0;
  position: relative; }
  @media screen and (max-width: 1460px) {
    #top #wrapper {
      padding: 60px 0 0; } }
  #top #wrapper:before {
    content: '';
    display: inline-block;
    background: url("/inc/image/common/bg/bg_silver_top_left.png") no-repeat center left/contain;
    width: 406.9px;
    height: 812.8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top #wrapper:before {
        width: 284.83px;
        height: 568.96px; } }
    @media screen and (max-width: 768px) {
      #top #wrapper:before {
        width: 244.14px;
        height: 487.68px; } }
    @media screen and (max-width: 568px) {
      #top #wrapper:before {
        width: 203.45px;
        height: 406.4px; } }

#top .p-service {
  padding-bottom: 70px;
  position: relative; }
  #top .p-service:before {
    content: '';
    display: inline-block;
    background: url("/inc/image/common/bg/bg_silver_bottom_left.png") no-repeat center bottom/contain;
    width: 867.5px;
    height: 866.5px;
    position: absolute;
    bottom: 0;
    left: -120px;
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top .p-service:before {
        width: 607.25px;
        height: 606.55px; } }
    @media screen and (max-width: 768px) {
      #top .p-service:before {
        width: 520.5px;
        height: 519.9px; } }
    @media screen and (max-width: 568px) {
      #top .p-service:before {
        width: 433.75px;
        height: 433.25px; } }
  #top .p-service:after {
    content: '';
    display: inline-block;
    background: url("/inc/image/common/bg/bg_silver_middle_right.png") no-repeat center right/contain;
    width: 611.7px;
    height: 1219px;
    position: absolute;
    top: -600px;
    right: 0;
    z-index: -1; }
    @media screen and (max-width: 1024px) {
      #top .p-service:after {
        width: 428.19px;
        height: 853.3px;
        top: -420px; } }
    @media screen and (max-width: 768px) {
      #top .p-service:after {
        width: 367.02px;
        height: 731.4px;
        top: -360px; } }
    @media screen and (max-width: 568px) {
      #top .p-service:after {
        width: 305.85px;
        height: 609.5px;
        top: -300px; } }

#top .p-news {
  margin-bottom: 70px; }
  #top .p-news__inner {
    display: flex; }
    @media screen and (max-width: 1024px) {
      #top .p-news__inner {
        flex-direction: column; } }
  #top .p-news__left {
    width: 25%; }
    @media screen and (max-width: 1024px) {
      #top .p-news__left {
        width: 100%; } }
  #top .p-news__right {
    width: 75%; }
    @media screen and (max-width: 1024px) {
      #top .p-news__right {
        width: 100%; } }
  @media screen and (max-width: 1024px) {
    #top .p-news__cat {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 0; } }
  #top .p-news__cat-item {
    margin-bottom: 12.5px; }
    @media screen and (max-width: 1024px) {
      #top .p-news__cat-item {
        margin-bottom: 0;
        margin-right: 25px; } }
    #top .p-news__cat-item:last-child {
      margin-bottom: 0; }
      @media screen and (max-width: 1024px) {
        #top .p-news__cat-item:last-child {
          margin-right: 0; } }
    #top .p-news__cat-item > a {
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      color: #A3A3A3;
      padding-bottom: 3px; }
      #top .p-news__cat-item > a:after {
        content: '';
        display: block;
        width: 0%;
        height: 1px;
        background-color: #000000;
        position: absolute;
        left: 0;
        bottom: 0; }
      #top .p-news__cat-item > a.is-currentCat {
        color: #000000;
        position: relative; }
        #top .p-news__cat-item > a.is-currentCat:after {
          width: 100%;
          transition: all 0.2s ease; }

#top .p-service .c-clipCard__heading {
  font-size: 22px; }
